<template>
  <v-container class="mt-12">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12" class="pt-12">
        <p
          class="caption"
          style="
            border-bottom: 1px solid black;
            padding-bottom: 5px;
            width: max-content;
            border-color: #a67d58;
          "
        >
          {{ $t("about.s3.WHAT_WE_DO") }}
        </p>
        <h2 class="accent--text" v-html="$t('about.s3.PROVIDE_THE_BEST')"></h2>
      </v-col>

      <v-col cols="12" lg="12" md="12" sm="12">
        <v-slide-group v-model="model" class="pa-4" show-arrows>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img src="../../assets/s2-2.png" width="300px" height="200px" />
              <p class="text-center">{{ $t("about.s3.Residential") }}</p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/photo_alsalam.jpg"
                width="300px"
                height="200px"
              />
              <p class="text-center">
                {{ $t("about.s3.AlSalamResidentialCompound") }}
              </p>
            </v-card>
          </v-slide-item>

          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/326515865_895111684957464_7202985253457194786_n.jpg"
                width="300px"
                height="200px"
              />
              <p class="text-center">{{ $t("about.s3.displayScreen") }}</p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/332868284_780810546251220_4081553586963183468_n.jpg"
                width="300px"
                height="200px"
              />
              <p class="text-center">
                {{ $t("about.s3.Assyriaphysicaleducation") }}
              </p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/320758278_1297047871059537_576269721118698138_n.jpg"
                width="300px"
                height="200px"
              />
              <p class="text-center">
                {{ $t("about.s3.fiberCable") }}
              </p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/Picture9.png"
                width="300px"
                height="200px"
              />
              <p class="text-center">
                {{ $t("about.s3.TheBowlingProject") }}
              </p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/Picture4444.jpg"
                width="300px"
                height="200px"
              />
              <p class="text-center">
                {{ $t("about.s3.SouthernLinkProjectHolyKarbala") }}
              </p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/Picture134.png"
                width="300px"
                height="200px"
              />
              <p class="text-center">
                {{ $t("about.s3.HematologyCenterMedicalCity") }}
              </p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/316549929_214842887602953_1513630241865333506_n.jpg"
                width="300px"
                height="200px"
              />
              <p class="text-center">
                {{ $t("about.s3.Firefighting") }}
              </p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img src="../../assets/is44.jpg" width="300px" height="200px" />
              <p class="text-center">
                {{ $t("about.s3.AlIsraaUniversity") }}
              </p>
            </v-card>
          </v-slide-item>
          <v-slide-item>
            <v-card flat class="ma-4" height="220" width="300">
              <img
                src="../../assets/Picture5.jpg"
                width="300px"
                height="200px"
              />
              <p class="text-center">
                {{ $t("about.s3.BodourResidentialCompoundBaghdad") }}
              </p>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Install",
  data: () => ({
    isActive: false,
  }),
  computed: {
    plugins() {
      return JSON.parse(JSON.stringify(this.$t("home.install.plugins")));
    },
  },
};
</script>

<style lang="scss" scoped></style>
